<template>
<b-form class="form" @submit.stop.prevent="onSubmit">
  <b-container fluid class="p-0">
    <b-row>
      <b-col cols="12" md="8" offset-md="2">
        <b-form-group id="input-group-3" :label="`${$t('FORM.LOGO')}`" label-for="input-3" label-cols-md="2" label-align-md="right">
          <FileImage
            id="input-3"
            v-model="$v.form.logo.$model"
            accept="image/*"
            aria-describedby="input-3-feedback"
            @input="clearServerError('logo')"
          />
          <b-form-invalid-feedback id="input-3-feedback" :class="{'d-block': validateState('logo') === false}">
            <template v-if="serverErrors.logo">{{ serverErrors.logo[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.LOGO') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-1" :label="`${$t('FORM.NAME')}`" label-for="input-1" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-1"
            v-model="$v.form.name.$model"
            :state="validateState('name')"
            :placeholder="$t('PLACEHOLDER.NAME')"
            trim
            @input="clearServerError('name')"
            aria-describedby="input-1-feedback"
          />
          <b-form-invalid-feedback id="input-1-feedback">
            <template v-if="serverErrors.name">{{ serverErrors.name[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.NAME') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-2" :label="`${$t('FORM.DOMAIN')}`" label-for="input-2" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-2"
            v-model="$v.form.domain.$model"
            :state="validateState('domain')"
            :placeholder="$t('PLACEHOLDER.DOMAIN')"
            @input="clearServerError('domain')"
            aria-describedby="input-2-feedback"
          />
          <b-form-invalid-feedback id="input-2-feedback">
            <template v-if="serverErrors.domain">{{ serverErrors.domain[0] }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.DOMAIN') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-4" :label="`${$t('FORM.PHONE')}`" label-for="input-4" label-cols-md="2" label-align-md="right">
          <InputForm
            id="input-4"
            v-model="$v.form.phone.$model"
            :state="validateState('phone')"
            :placeholder="$t('PLACEHOLDER.PHONE')"
            @input="clearServerError('phone')"
            aria-describedby="input-4-feedback"
          />
          <b-form-invalid-feedback id="input-4-feedback">
            <template v-if="serverErrors.phone">{{ serverErrors.phone[0] }}</template>
            <template v-else-if="!$v.form.phone.alpha">{{ $t('VALIDATION.INVALID', { name: $t('FORM.PHONE') }) }}</template>
            <template v-else>{{ $t('VALIDATION.REQUIRED', { name: $t('FORM.PHONE') }) }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group id="input-group-5" :label="`${$t('FORM.DESCRIPTION')}`" label-for="input-5" label-cols-md="2" label-align-md="right">
          <Textarea
            id="input-5"
            v-model="$v.form.description.$model"
            :state="validateState('description')"
            :placeholder="$t('PLACEHOLDER.DESCRIPTION')"
            @input="clearServerError('description')"
            aria-describedby="input-5-feedback"
          />
          <b-form-invalid-feedback id="input-5-feedback">
            <template v-if="serverErrors.description">{{ serverErrors.description[0] }}</template>
          </b-form-invalid-feedback>
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <div class="text-right">
          <b-button :disabled="loading || $v.form.$anyError" type="submit" variant="info" size="sm">
            <b-spinner v-if="loading" small variant="light" />
            {{ $t('FORM.SUBMIT') }}
          </b-button>
        </div>
      </b-col>
    </b-row>
  </b-container>
</b-form>
</template>
<script>
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module'
import { validationMixin } from 'vuelidate'
import { required, helpers } from 'vuelidate/lib/validators'
import InputForm from '@/components/forms-items/input'
import serverVuelidate from '@/mixins/serverVuelidate'
import { TEL } from '@/helpers/validation'
import Textarea from '@/components/forms-items/Textarea'
import FileImage from '@/components/forms-items/FileImage'
import Repo from '@/core/repository/admin/companyRepository'
import RepoFile from '@/core/repository/company/fileRepository'
import { TIMEOUT_REDIRECT } from '@/constants/base'

export default {
  name: 'EditForm',
  components: {
    FileImage,
    Textarea,
    InputForm, 
  },
  mixins: [validationMixin, serverVuelidate],
  data() {
    return {
      loading: false,
      file: null,
      form: {
        name: '',
        domain: '',
        description: '',
        phone: '',
        logo: null,
      },
      validations: {
        form: {
          name: { required },
          domain: { required },
          logo: { required },
          description: {},
          phone: {
            required,
            alpha: helpers.regex('alpha', TEL), 
          },
        },
      },
    }
  },
  mounted() {
    this.fetchData()
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t('COMPANY.TITLE_EDIT') }])
  },
  methods: {
    fetchData() {
      Repo.get(this.$route.params.id).then(({ data }) => {
        this.setItem(data.payload)
        // this.form = data.payload
      })
    },
    setItem(item) {
      Object.keys(this.form).forEach((propName) => {
        if (
          this.form.hasOwnProperty(propName)
            && item.hasOwnProperty(propName)
        ) {
          if (item[propName] !== null && item[propName] !== undefined) {
            this.form[propName] = item[propName]
          }
        }
      })
    },
    validateState(name) {
      const { $dirty, $error } = this.$v.form[name]
      return $dirty ? !$error : null
    },
    addFile(file) {
      if (file.uuid) return file
      const formData = new FormData()
      formData.append('file', file)
      return RepoFile.post(formData)
    },
    async onSubmit() {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return
      }
      this.clearServerErrors()
      this.loading = true
      const obj = { ...this.form }
      if (!this.form.logo.uuid) {
        const { data } = await this.addFile(this.form.logo)
        obj.logo = data.payload.uuid
      } else obj.logo = obj.logo.uuid
      Repo.patch(this.$route.params.id, obj).then(() => {
        this.$bvToast.toast(this.$t('TOAST.EDITED'), {
          title: 'Success',
          variant: 'success',
          autoHideDelay: 2000,
          solid: true,
        })
        setTimeout(() => this.$router.push({ name: 'company' }), TIMEOUT_REDIRECT)
        this.loading = false
      }).catch((err) => {
        this.loading = false
        this.seServerErrors(err.response.data)
      })
    },
  },
}
</script>
